import React from 'react';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../components/TitleHeaderText';
import { HowItWorksData } from './data';
import HowItWorks from '../shared/HowItWorksV2/index';

const MainDiv = styled.div``;

function BusinessHowItWorks() {
  return (
    <MainDiv>
      <TitleHeaderText text="How it works" />
      <HowItWorks className="home business" data={HowItWorksData} />
    </MainDiv>
  );
}

export default BusinessHowItWorks;
