import React from 'react';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../../components/TitleHeaderText';
import Members from '../MembersV2';
import background from '../../BusinessPage/assets/background.png';

const Container = styled.div`
  background-image: url(${background});
  background-size: 100% 100%;
  background-position: 35px -100px;
  padding-top: 3rem;
  @media (max-width: 760px) {
    background-image: none;
  }
`;

// TODO(Matt): Replace Meet Out Community V1 when all pages are optimised
function MeetOurCommunity({ data, FAQBackground }) {
  return (
    <Container>
      <TitleHeaderText text="Meet our community" />
      <br />
      <Members FAQBackground={FAQBackground} data={data} />
    </Container>
  );
}

export default MeetOurCommunity;
