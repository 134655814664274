import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import colors from '../assets/colors';

import TopHeader from '../views/shared/TopHeaderV2';
import AdvantagesOfGoget from '../views/shared/AdvantagesOfGogetV2';
import HowWeCanHelp from '../views/BusinessPage/HowWeCanHelp';
import BusinessHowItWorks from '../views/BusinessPage/BusinessHowItWorks';
import BusinessManpower from '../views/BusinessPage/BusinessManpower';
import Banner from '../views/shared/Banner';
import MeetOurCommunity from '../views/shared/MeetOurCommunity';
import FAQSection from '../views/shared/FAQ';
import ContactSection from '../components/ContactSection';
import HeaderSeo from '../components/HeaderSeo';

import { CommunityData } from '../views/BusinessPage/data';

import { FaqData } from '../views/BusinessPage/data';

import MobileBusinessFooterBackground from '../assets/img/business/MobileBusinessFooterBackground.png';
import DesktopFooterBackground from '../assets/img/business/DesktopFooterBackground.png';

import MetaImg from '../assets/img/meta/business.png';

import { SharedBackground } from '../assets/sharedStyle';
import { graphql } from 'gatsby';

const BannerImage = '../assets/img/business/packerDesktop.png';
const MobileBannerImage = '../assets/img/business/packerMobile.png';

const BackgroundFooter = styled.div`
  ${SharedBackground};
  background-image: url(${DesktopFooterBackground});
  @media (max-width: 760px) {
    background-image: url(${MobileBusinessFooterBackground});
  }
`;
const ContactUsContainer = styled.div`
  margin-top: -2rem;
`;

const BusinessPage = props => {
  const {
    data: { datoCmsBusiness }
  } = props;

  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash === 'contact-us') {
      setTimeout(() => {
        const extraSize = mobile ? 350 : -10;
        const contactUs =
          document.getElementById('contact-us').offsetTop + extraSize;
        window.scroll({ top: contactUs, behavior: 'smooth' });
      }, 700);
    } else {
      window.scrollTo(0, 0);
    }
    window.addEventListener('resize', sizeHandler);
    return sizeHandler();
  }, [mobile]);

  const sizeHandler = () => {
    if (window.innerWidth <= 800) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const desktopImage = getImage(datoCmsBusiness?.bannerImageDesktop);
  const mobileImage = getImage(datoCmsBusiness?.bannerImageMobile);

  return (
    <>
      <HeaderSeo
        title="GoGet | On Demand Part Timers For Businesses"
        description="Lower staff costs and pay only when needed. Peace of mind with hiring part timers and avoid stress due to ups and downs. You can have part-timers on-demand!"
        image={MetaImg}
        keywords="goget business plan, hire part timer for business, temp agency, manpower recruitment agency"
        pathname={props.location.pathname}
        schemaMarkup={datoCmsBusiness.schemaMarkup}
      />
      <Layout showHeaderButtons hideDownloadGoGetter isShowingFooterAppBar>
        <TopHeader
          backgroundImg={
            <GatsbyImage
              image={desktopImage}
              loading="eager"
              style={{ height: '100%' }}
              quality={90}
              alt={datoCmsBusiness?.bannerImageDesktop?.alt}
            />
          }
          mobileBackgroundImg={
            <GatsbyImage
              image={mobileImage}
              loading="eager"
              alt={datoCmsBusiness?.bannerImageMobile?.alt}
            />
          }
          showVideo={true}
          title={datoCmsBusiness?.headerTitle}
          description={datoCmsBusiness?.description}
          btn1={{ text: 'SEE OUR PRICES', link: '/business/pricing' }}
          hidePlay
        />
        <AdvantagesOfGoget data={datoCmsBusiness?.advantages} />
        <HowWeCanHelp />
        <BusinessManpower
          title={datoCmsBusiness?.solutionsTitle}
          solutions={datoCmsBusiness?.solutions}
        />
        <BusinessHowItWorks />
        <br />
        <Banner
          backgroundColor={colors.secondary}
          image={
            <StaticImage
              src={BannerImage}
              placeholder="blurred"
              style={{ height: '100%' }}
              alt="Banner image"
            />
          }
          mobileImage={
            <StaticImage
              src={MobileBannerImage}
              placeholder="blurred"
              alt="Banner image"
            />
          }
          title="Packer"
          subText="Hire reliable packers for all your logistical needs."
          btn1={{ text: 'LEARN MORE', link: '/packer', type: 'primary' }}
        />
        <MeetOurCommunity data={CommunityData} />
        <BackgroundFooter>
          <FAQSection data={FaqData} />
          <ContactUsContainer id="contact-us">
            <ContactSection
              hideBackground
              title={<div> Let us help grow your business! </div>}
              subtext="Provide us with your details and we will get in touch."
            />
          </ContactUsContainer>
        </BackgroundFooter>
      </Layout>
    </>
  );
};

export default BusinessPage;

export const query = graphql`
  query BusinessQuery {
    datoCmsBusiness {
      headerTitle
      description
      bannerImageDesktop {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
      bannerImageMobile {
        gatsbyImageData(width: 600, placeholder: BLURRED)
        alt
      }
      advantages {
        image {
          gatsbyImageData(width: 200, placeholder: BLURRED)
          alt
        }
        header
        description
      }
      solutionsTitle
      solutions {
        cardColor {
          hex
        }
        textColor {
          hex
        }
        heroImage {
          gatsbyImageData(placeholder: BLURRED, width: 2160)
          alt
        }
        toggleButtonText
        toggleButtonIcon {
          gatsbyImageData(placeholder: BLURRED, height: 35)
          alt
        }
        toggleButtonIconActive {
          gatsbyImageData(placeholder: BLURRED, height: 35)
          alt
        }
        title
        subtitle
        description
        reverseDirection
        titleImage {
          alt
          gatsbyImageData(placeholder: BLURRED, width: 150)
        }
        ctaPath
        ctaText
        hiringPerks {
          image {
            gatsbyImageData(placeholder: BLURRED, width: 150, height: 150)
            alt
          }
          text
          ctaPath
        }
      }
      schemaMarkup
    }
  }
`;
