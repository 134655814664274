import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Link from 'gatsby-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArrowForward from '@material-ui/icons/ArrowForward';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import IconButton from '@material-ui/core/IconButton';

import TitleHeader from '../../components/TitleHeaderText';

import fontSize from '../../assets/fontSize';
import colors from '../../assets/colors';
import { ButtonBase } from '@material-ui/core';

const Container = styled.div`
  margin-top: -50px;
`;
const MainDiv = styled.div`
  width: 80%;
  max-width: 1100px;
  margin: auto;
`;
const Content = styled.div`
  margin-top: 30px;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
  align-items: stretch;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;
const ToggleCard = styled(Card)`
  && {
    color: ${({ active }) => (active ? colors.primary : colors.black)};
    border: 3px solid
      ${({ active }) => (active ? colors.primary : 'transparent')};
    border-radius: 16px;
  }
`;
const ToggleButton = styled(CardActionArea)`
  && {
    height: 100%;
    padding: 1.25rem;
    font-weight: 600;
    line-height: 22px;
    font-size: ${fontSize.xLarge};
    text-transform: none;

    @media (min-width: 980px) {
      max-width: 250px;
    }
  }
`;
const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const StyledCard = styled(Card)`
  && {
    display: ${({ active }) => (active ? 'block' : 'none')};
    color: ${({ textColor }) => textColor};
    background-color: ${({ cardColor }) => cardColor};
    border-radius: 16px;
  }
`;
const CardContent = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: ${({ mobile, reverse }) =>
    mobile ? 'column' : reverse ? 'row-reverse' : 'row'};
  align-items: center;
  gap: 1rem;
`;
const HeroImage = styled.div`
  max-width: 550px;
`;
const ContentArea = styled.div`
  flex-grow: 1;
`;
const SolutionDescription = styled.p`
  margin: 1rem 0;
`;
const SolutionSubtitle = styled.h3`
  font-size: ${fontSize.oneHalfRem};
  font-weight: 600;
  margin-top: 0.25rem;
`;
const SolutionTitle = styled.h2`
  font-size: ${fontSize.large};
  font-weight: 600;
  margin: 0;
`;
const PerksContainer = styled.div`
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
  align-items: ${({ mobile }) => !mobile && 'center'};
  gap: 1rem;
  margin-top: 1.5rem;
`;
const PerkImage = styled.div`
  width: 28px;
  height: 28px;
`;
const PerkWrap = styled(ButtonBase)`
  display: flex;
  gap: ${({ mobile }) => mobile && '0.5rem'};
  flex-direction: ${({ mobile }) => (mobile ? 'row' : 'column')};
  align-items: ${({ mobile }) => (mobile ? 'center' : 'flex-start')};
  justify-content: flex-start;
  padding: 0.25rem;
  padding-top: 0.5rem;
  border-radius: 8px;
`;
const PerkCtaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
const PerkText = styled.p`
  font-size: ${fontSize.large};
  margin: 0.25rem 0;
`;

const StyledButton = styled(Button)`
  && {
    color: ${colors.white};
    margin: ${({ mobile }) => mobile && '0 auto'};
    display: flex;
    width: 240px;
    height: 60px;
    margin-top: 1rem;
    font-weight: bold;
    border-radius: 8px;
    font-size: ${fontSize.large};
  }
`;

const BusinessManpower = ({ title, solutions }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setMobile] = useState(false);

  const updateWindowDimensions = () => {
    if (window.innerWidth <= 980 && !isMobile) {
      setMobile(true);
    } else if (window.innerWidth > 980 && isMobile) {
      setMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);

    updateWindowDimensions();

    return () => window.removeEventListener('resize', updateWindowDimensions);
  });

  const onClickThroughCta = (label, path) => {
    window.analytics.track('body_clickthrough', {
      category: 'clickthrough',
      label: label?.replace(/ /g, '_').toLowerCase(),
      destination: path,
      platform: 'static'
    });
  };

  return (
    <Container>
      <TitleHeader text={title} />
      <MainDiv className="home business">
        <Content>
          <ButtonContainer mobile={isMobile}>
            {solutions.map((solution, index) => {
              const toggleActiveImage = solution.toggleButtonIconActive
                ? getImage(solution.toggleButtonIconActive)
                : null;
              const toggleImage = solution.toggleButtonIcon
                ? getImage(solution.toggleButtonIcon)
                : null;

              return (
                <ToggleCard key={index} active={activeTab === index}>
                  <ToggleButton onClick={() => setActiveTab(index)}>
                    <ButtonContent>
                      {solution.toggleButtonText}
                      {solution.toggleButtonIcon &&
                        solution.toggleButtonIconActive && (
                          <GatsbyImage
                            image={
                              activeTab === index
                                ? toggleActiveImage
                                : toggleImage
                            }
                            alt={solution.toggleButtonIcon.alt}
                          />
                        )}
                    </ButtonContent>
                  </ToggleButton>
                </ToggleCard>
              );
            })}
          </ButtonContainer>
          {solutions.map((solution, index) => {
            return (
              <StyledCard
                key={solution.title}
                active={activeTab === index}
                cardColor={solution.cardColor.hex}
                textColor={solution.textColor.hex}
              >
                <CardContent
                  mobile={isMobile}
                  reverse={solution.reverseDirection}
                >
                  <HeroImage>
                    <GatsbyImage
                      image={getImage(solution.heroImage)}
                      alt={solution.heroImage.alt}
                      quality={90}
                    />
                  </HeroImage>
                  <ContentArea>
                    {solution.titleImage && (
                      <GatsbyImage
                        image={getImage(solution.titleImage)}
                        alt={solution.titleImage.alt}
                      />
                    )}
                    {solution.title && (
                      <SolutionTitle>{solution.title}</SolutionTitle>
                    )}
                    {solution.subtitle && (
                      <SolutionSubtitle>{solution.subtitle}</SolutionSubtitle>
                    )}
                    {solution.description && (
                      <SolutionDescription>
                        {solution.description}
                      </SolutionDescription>
                    )}
                    {solution.hiringPerks && (
                      <PerksContainer mobile={isMobile}>
                        {solution.hiringPerks.map(perk => {
                          return (
                            <PerkWrap
                              key={perk.text}
                              component={Link}
                              to={perk.ctaPath}
                              onClick={() =>
                                onClickThroughCta(perk.text, perk.ctaPath)
                              }
                              mobile={isMobile}
                              color="primary"
                            >
                              <PerkImage>
                                <GatsbyImage
                                  image={getImage(perk.image)}
                                  alt={perk.image.alt}
                                />
                              </PerkImage>
                              <PerkCtaContainer>
                                <PerkText>{perk.text}</PerkText>
                                <ArrowForward
                                  fontSize="small"
                                  color="primary"
                                />
                              </PerkCtaContainer>
                            </PerkWrap>
                          );
                        })}
                      </PerksContainer>
                    )}
                    {solution.ctaText && solution.ctaPath && (
                      <StyledButton
                        component={Link}
                        to={solution.ctaPath}
                        variant="contained"
                        color="primary"
                        mobile={isMobile}
                        target={
                          solution.ctaPath.includes('https://')
                            ? '_blank'
                            : '_self'
                        }
                        onClick={() =>
                          onClickThroughCta(solution.ctaText, solution.ctaPath)
                        }
                      >
                        {solution.ctaText}
                      </StyledButton>
                    )}
                  </ContentArea>
                </CardContent>
              </StyledCard>
            );
          })}
        </Content>
      </MainDiv>
    </Container>
  );
};

export default BusinessManpower;
