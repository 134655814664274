import React from 'react';
import styled from 'styled-components/macro';

import HowToHelpBackgroundDesktop from '../../assets/img/business/howToHelpBackgroundDesktop.png';
import HerehowwecanhelpMobileBackground from '../../assets/img/business/HerehowwecanhelpMobileBackground.png';

import TitleHeaderText from '../../components/TitleHeaderText';
import ProfileCard from '../../components/ProfileCardV2/index';
import Button from '../../components/Button';
import { HoWeCanHelpData } from './data';

const MainDiv = styled.div`
  background-image: url(${HowToHelpBackgroundDesktop});
  box-sizing: border-box;
  background-size: cover;
  z-index: -1;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 10rem;
  margin-top: 2rem;
  top: -1px;
  background-position: center top;
  text-align: center;
  @media (max-width: 760px) {
    background-image: url(${HerehowwecanhelpMobileBackground});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
`;

function HowWeCanHelp() {
  const onBannerBtnClick = () => {
    const topOfElement = document.querySelector('#contact-us').offsetTop - 170;
    window.scroll({ top: topOfElement, behavior: 'smooth' });
    window.analytics.track('body_clickthrough', {
      category: 'clickthrough',
      label: 'CONTACT ME',
      destination: '',
      platform: 'static'
    });
  };

  return (
    <MainDiv>
      <TitleHeaderText text="Here's how we can help" />
      <ProfileCard
        cardWidth={'250px'}
        cardHight={'132px'}
        disableScroll
        data={HoWeCanHelpData}
        linkText={'See All Services'}
      />
      <br />
      {/* eslint-disable */}
      <div
        role="button"
        onClick={onBannerBtnClick}
        style={{ zIndex: 0 }}
        tabIndex={0}
      >
        <Button primary background={'primary'}>
          {' '}
          CONTACT ME{' '}
        </Button>
      </div>
      {/* eslint-enable */}
    </MainDiv>
  );
}

export default HowWeCanHelp;
